import React from "react";
import { motion } from "framer-motion";
import { FaCheckCircle } from "react-icons/fa"; // Kept the relevant icon
import "./DPIITPageZZ1.css";

const DPIITInfoPageZZ1 = () => {
  return (
    <div className="dpiit-page-ZZ1">
      {/* Header Section with Container */}
      <header className="dpiit-header-container-ZZ1">
        <motion.div
          className="dpiit-header-ZZ1"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <motion.h1
            className="header-title-ZZ1"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            DPIIT Recognition
          </motion.h1>
          <motion.p
            className="header-subtitle-ZZ1"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 1 }}
          >
            Enabling startups to unlock exclusive benefits and scale their business effortlessly.
          </motion.p>
        </motion.div>
      </header><br></br>

      {/* About DPIIT Section */}
      <section className="dpiit-about-ZZ1">
        <motion.h2
          className="section-title-ZZ1"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          What is DPIIT Recognition? <FaCheckCircle />
        </motion.h2>
        <p className="about-text-ZZ1">
          The Department for Promotion of Industry and Internal Trade (DPIIT) provides recognition to startups that meet specific eligibility criteria. This recognition helps startups gain access to numerous government benefits, tax exemptions, funding support, and simplified compliance processes.
        </p>
      </section>

      {/* Benefits Section */}
      <section className="dpiit-benefits-ZZ1">
        <motion.h2
          className="section-title-ZZ1"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          Key Benefits of DPIIT Recognition <FaCheckCircle />
        </motion.h2>
        <ul className="benefits-list-ZZ1">
          {[
            "Access to tax exemptions for three consecutive years.",
            "Priority in government tenders and procurement.",
            "Fast-tracking of intellectual property applications.",
            "Eligibility for Fund of Funds to boost financing.",
            "Relaxed norms for public procurement and compliance.",
            "Opportunities to network with investors and mentors.",
          ].map((benefit, index) => (
            <motion.li
              key={index}
              className="benefit-item-ZZ1"
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
            >
              {benefit}
            </motion.li>
          ))}
        </ul>
      </section>

      {/* Eligibility Section */}
      <section className="dpiit-eligibility-ZZ1">
        <motion.h2
          className="section-title-ZZ1"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          Eligibility Criteria <FaCheckCircle />
        </motion.h2>
        <p className="eligibility-text-ZZ1">
          To qualify for DPIIT recognition, your startup must meet the following criteria:
        </p>
        <ul className="eligibility-list-ZZ1">
          {[
            "Incorporated as a Private Limited Company, LLP, or Registered Partnership.",
            "Business should not exceed ten years from the date of incorporation.",
            "Annual turnover must not exceed ₹100 crore.",
            "Startup must work towards innovation, development, or improvement of products or services.",
            "Must not have been formed by splitting or reconstructing an existing business.",
          ].map((criterion, index) => (
            <motion.li
              key={index}
              className="eligibility-item-ZZ1"
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
            >
              {criterion}
            </motion.li>
          ))}
        </ul>
      </section>

      {/* Process Section */}
      <section className="dpiit-process-ZZ1">
        <motion.h2
          className="section-title-ZZ1"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          How to Apply for DPIIT Recognition <FaCheckCircle />
        </motion.h2>
        <ol className="process-list-ZZ1">
          {[
            "Register your startup on the Startup India portal.",
            "Complete the DPIIT recognition application form.",
            "Upload required documents, including Certificate of Incorporation.",
            "Submit a brief description of your startup’s innovative nature.",
            "Track the application status online.",
            "Receive DPIIT recognition upon successful evaluation.",
          ].map((step, index) => (
            <motion.li
              key={index}
              className="process-item-ZZ1"
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
            >
              {step}
            </motion.li>
          ))}
        </ol>
      </section>
    </div>
  );
};

export default DPIITInfoPageZZ1;
