import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NotificationPage from './NotificationPage';
import './Header.css';
import 'font-awesome/css/font-awesome.min.css';
import PlacesAutocomplete from "react-places-autocomplete";
import { IoLocationOutline } from "react-icons/io5";

const Header = ({ isLoggedIn, setIsLoggedIn }) => {
  const navigate = useNavigate();
  const [showNotifications, setShowNotifications] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isTextbox, setIsTextbox] = useState(false);
  const [address, setAddress] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);

  const handleSelect = (address) => {
    setAddress(address);
    setSelectedLocation(address);
    setIsTextbox(false);
  };
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleCategoryClick = (path) => {
    setIsSidebarOpen(false); // Close the sidebar
    navigate(path);
  };
  const [profileImage, setProfileImage] = useState("/images/avatar.jpeg"); // Default image

  
  useEffect(() => {
    // On component mount, check if the user is logged in
    const storedLoginState = localStorage.getItem("isLoggedIn");
    setIsLoggedIn(storedLoginState === "true"); // Convert string to boolean

    // Listen for page unload or refresh to reset the login state
    const handleUnload = () => {
      localStorage.setItem("isLoggedIn", "false"); // Reset login state
    };

    window.addEventListener("beforeunload", handleUnload);
    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, [setIsLoggedIn]);

  useEffect(() => {
    // Retrieve profile image from localStorage
    const storedImage = localStorage.getItem("profileImage");
    if (storedImage) {
      setProfileImage(storedImage);
    }
  }, []);

  const notifications = [
    { message: 'You have a new startup request from XYZ Corp. Click to review.', date: '2024-10-30' },
    { message: 'Your application for ABC Startup has been approved! Welcome aboard!', date: '2024-10-29' },
    { message: 'Don’t miss out! Join our webinar on startup funding this Friday.', date: '2024-10-28' },
    { message: 'Reminder: Complete your profile for better matches with investors.', date: '2024-10-27' },
    { message: 'New features added to the platform. Check them out now!', date: '2024-10-26' },
  ];


  const recognitionCategories = [
    { name: 'DPIIT Recognition and Benefits', path: '/Dpiit2' },
    // { name: 'Apply for DPIIT Recognition', path: '/Dpiit1' },
    { name: 'Verify/Download Certificate', path: '/Form' },
    // { name: 'Self Certification', path: '/SelfCertification' },
    { name: 'Regulatory Updates', path: '/RegulationsTable' },
    // { name:  'IncomeTaxExemption', path:'/IncomeTaxExemption'},
    {name: 'ApplicationStatus', path:'/application'}, 
  ];

  const handleMouseEnter = (dropdown) => setActiveDropdown(dropdown);
  const handleMouseLeave = () => setActiveDropdown(null);

  

  const handleSearchClick = () => {
    if (searchText.trim() !== '') {
      navigate(`/search?query=${encodeURIComponent(searchText)}`);
    } else {
      alert('Please enter a search query.');
    }
  };

  const handleVoiceSearch = () => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (!SpeechRecognition) {
      alert('Speech Recognition is not supported in this browser.');
      return;
    }
    const recognition = new SpeechRecognition();
    recognition.lang = 'en-US';
    recognition.start();
    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setSearchText(transcript);
    };
    recognition.onerror = (event) => {
      alert(`Error in speech recognition: ${event.error}`);
    };
  };

  const handleLoginClick = () => navigate('/login');
  const handleLogoutClick = () => {
    localStorage.setItem("isLoggedIn", "false"); // Update stored login state
    setProfileImage("/images/avatar.jpeg");
    setIsLoggedIn(false);
    navigate('/login');
  };
  const handleDashboardClick = () =>{
    navigate('/dashboard');
  }

  const handleRegisterClick = () => navigate('/register');

  const handleNotificationClick = () => setShowNotifications(true);
  const handleCloseNotifications = () => setShowNotifications(false);

  return (
    <>
      <header className="header123">
        <div className="logo">
          <img src="/images/logo.png" alt="Logo" />
          <div className="logo-text">
            <h1>CONNECT TO STARTUP</h1>
            <p>Feel Free To Connect</p>
          </div>
        </div>
        <div className="menu-buttons">
          
         
          <div className="profile-pic" onClick={handleDashboardClick}>
            <img src={profileImage} alt="Profile" />
          </div>
          <button
            className="menu-button"
            onClick={isLoggedIn ? handleLogoutClick : handleLoginClick}
          >
            {isLoggedIn ? 'Logout' : 'Login'}
          </button>
          {!isLoggedIn && (
            <button className="menu-button" onClick={handleRegisterClick}>
              Register
            </button>
          )}
          <div className="notification-icon" onClick={handleNotificationClick}>
            <i className="fa fa-bell" aria-hidden="true"></i>
          </div>
        </div>
        
        <div className="location-container">
      <IoLocationOutline
        className="location-icon"
        onClick={() => setIsTextbox(true)}
      />
      {isTextbox ? (
        <PlacesAutocomplete
          value={address}
          onChange={setAddress}
          onSelect={handleSelect}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div className="search-container">
              <input
                {...getInputProps({
                  placeholder: "Search location...",
                  className: "location-input",
                })}
              />
              <div className="suggestions-list">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const style = {
                    backgroundColor: suggestion.active ? "#f0f0f0" : "#fff",
                  };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, { style })}
                      key={suggestion.placeId}
                      className="suggestion-item"
                    >
                      {suggestion.description}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      ) : (
        <input
          type="text"
          value={selectedLocation}
          readOnly
          className="selected-location-input"
          placeholder="Select a location"
        />
      )}
    </div>
        <div className="search-bar13">
          <input
            type="text"
            placeholder="Search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <button className="voice-search" onClick={handleVoiceSearch}>
            <img src="/images/mic.jpeg" alt="Voice Search" />
          </button>
          <button className="search-button" onClick={handleSearchClick}>
            <img src="/images/search.jpeg" alt="Search" />
          </button>
        </div>
        
       
      </header>
      <div className="hamburger" onClick={toggleSidebar}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
      <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
        <button className="close-btn" onClick={toggleSidebar}>
          &times;
        </button>
        <nav className="sidebar-links">
          <button
            className="sidebar-link"
            onClick={() => {
              setIsSidebarOpen(false);
              navigate("/home");
            }}
          >
            Home
          </button>
          <button
            className="sidebar-link"
            onClick={() => {
              setIsSidebarOpen(false);
              navigate("/aboutus");
            }}
          >
            About
          </button>

          {/* Recognition Dropdown */}
          <div
            className="sidebar-link-dropdown"
            onMouseEnter={() => handleMouseEnter("recognitionCategories")}
            onMouseLeave={handleMouseLeave}
          >
            <button className="sidebar-link">Recognition</button>
            {activeDropdown === "recognitionCategories" && (
              <div className="dropdown-menu">
                {recognitionCategories.map((category, index) => (
                  <div
                    key={index}
                    className="dropdown-item"
                    onClick={() => handleCategoryClick(category.path)}
                  >
                    {category.name}
                  </div>
                ))}
              </div>
            )}
          </div>

          <button
            className="sidebar-link"
            onClick={() => {
              setIsSidebarOpen(false);
              navigate("/startup");
            }}
          >
            StartUp
          </button>
          <button
            className="sidebar-link"
            onClick={() => {
              setIsSidebarOpen(false);
              navigate("/Investor1");
            }}
          >
            Investor
          </button>
          <button
            className="sidebar-link"
            onClick={() => {
              setIsSidebarOpen(false);
              navigate("/Mentorlist");
            }}
          >
            Mentor
          </button>

          <button
            className="sidebar-link"
            onClick={() => {
              setIsSidebarOpen(false);
              navigate("/contactus");
            }}
          >
            Help & Support
          </button>
        </nav>
      </div>
      <nav className="nav-links">
        <button className="nav-link" onClick={() => navigate('/home')}>Home</button>
        <button className="nav-link" onClick={() => navigate('/aboutus')}>About</button>
<div
  className="nav-link-dropdown"
  onMouseEnter={() => handleMouseEnter('recognitionCategories')} // Ensure the key matches
  onMouseLeave={handleMouseLeave}
>
  <button className="nav-link">Recognition</button>

  {activeDropdown === 'recognitionCategories' && (
    <div className="dropdown-menu">
      {recognitionCategories.map((category, index) => (
        <div
          key={index}
          className="dropdown-item"
          onClick={() => handleCategoryClick(category.path)}
        >
          {category.name}
        </div>
      ))}
    </div>
  )}
</div>

        <button className="nav-link" onClick={() => navigate('/startup')}>StartUp</button>
        <button className="nav-link" onClick={() => navigate('/Investor1')}>Investor</button>
        <button className="nav-link" onClick={() => navigate('/Mentorlist')}>Mentor</button>
    

        <button className="nav-link" onClick={() => navigate('/contactus')}>
          Help & Support
        </button>
      </nav>

      {showNotifications && (
        <NotificationPage notifications={notifications} onClose={handleCloseNotifications} />
      )}
    </>
  );
};

export default Header;