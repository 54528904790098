// import React, { useState } from "react";
// import "./Startup.css";
// import Select from "react-select"; // Import react-select
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faStar, faStarHalfAlt } from "@fortawesome/free-solid-svg-icons";
// import { faStar as faStarEmpty } from "@fortawesome/free-regular-svg-icons";

// // Dropdown options for filters
// const industries = ["Technology", "Healthcare", "Education"].map((industry) => ({
//   value: industry,
//   label: industry,
// }));
// const states = ["California", "Texas", "New York"].map((state) => ({
//   value: state,
//   label: state,
// }));
// const cities = ["Los Angeles", "Houston", "New York City"].map((city) => ({
//   value: city,
//   label: city,
// }));

// // Startup data
// const startups = [
//   {
//     id: 1,
//     logo: "/images/logo startup.jpg",
//     name: "NANO NEXUS LIMITED",
//     stage: "Ideation",
//     rating: 4.5,
//     location: "California",
//     category: "Technology",
//     dpiitVerified: true,
//   },
//   {
//     id: 2,
//     logo: "/images/logo-startup.jpg",
//     name: "Qumir Nano",
//     stage: "Early Traction",
//     rating: 3,
//     location: "Texas",
//     category: "Healthcare",
//     dpiitVerified: false,
//   },
//   {
//     id: 3,
//     logo: "/images/logo-startup.jpg",
//     name: "EduConnect",
//     stage: "Growth",
//     rating: 4,
//     location: "New York",
//     category: "Education",
//     dpiitVerified: true,
//   },
// ];

// // Component to display rating stars
// const renderRatingStars = (rating) => {
//   const fullStars = Math.floor(rating);
//   const halfStar = rating % 1 !== 0 ? <FontAwesomeIcon icon={faStarHalfAlt} className="half-star" /> : null;
//   const emptyStars = 5 - Math.ceil(rating);

//   return (
//     <div className="rating">
//       {Array(fullStars)
//         .fill()
//         .map((_, i) => (
//           <FontAwesomeIcon key={i} icon={faStar} className="full-star" />
//         ))}
//       {halfStar}
//       {Array(emptyStars)
//         .fill()
//         .map((_, i) => (
//           <FontAwesomeIcon key={i} icon={faStarEmpty} className="empty-star" />
//         ))}
//     </div>
//   );
// };

// // Component for each startup card
// const StartupCard = ({ logo, name, stage, rating, location, category }) => (
//   <div className="startup-card">
//     <img src={logo} alt={`${name} logo`} className="startup-logo" />
//     <div className="startup-info">
//       <h3>{name}</h3>
//       <p>{stage}</p>
//       <p>{location}</p>
//       {renderRatingStars(rating)}
//       <p className="category">{category}</p>
//     </div>
//   </div>
// );

// // Main Startup component
// const Startup = () => {
//   const [filters, setFilters] = useState({
//     sortBy: "",
//     rating: 0,
//     dpiitVerified: false,
//     industry: null,
//     state: null,
//     city: null,
//   });

//   // Handle filter changes
//   const handleFilterChange = (filterKey, value) => {
//     setFilters((prevFilters) => ({
//       ...prevFilters,
//       [filterKey]: value,
//     }));
//   };

//   // Filter startups based on applied filters
//   const filteredStartups = startups.filter(
//     (startup) =>
//       (filters.rating === 0 || startup.rating >= filters.rating) &&
//       (filters.dpiitVerified === false || startup.dpiitVerified === filters.dpiitVerified) &&
//       (filters.industry === null || startup.category === filters.industry.value) &&
//       (filters.state === null || startup.location === filters.state.value)
//   );

//   return (
//     <div className="startup-container">
//       {/* Filters Section */}
//       <div className="filters-container">
//         <select onChange={(e) => handleFilterChange("sortBy", e.target.value)}>
//           <option value="">Sort By</option>
//           <option value="rating">Rating</option>
//           <option value="name">Name</option>
//         </select>

//         <select onChange={(e) => handleFilterChange("rating", parseFloat(e.target.value))}>
//           <option value="0">All Ratings</option>
//           <option value="3.5">3.5+</option>
//           <option value="4.0">4.0+</option>
//           <option value="4.5">4.5+</option>
//           <option value="5.0">5.0+</option>
//         </select>

//         <label>
//           DPIIT Verified
//           <input
//             type="checkbox"
//             onChange={(e) => handleFilterChange("dpiitVerified", e.target.checked)}
//           />
//         </label>

//         {/* Searchable Dropdowns */}
//         <Select
//           options={industries}
//           placeholder="Industry"
//           onChange={(value) => handleFilterChange("industry", value)}
//           isClearable
//         />
//         <Select
//           options={states}
//           placeholder="State"
//           onChange={(value) => handleFilterChange("state", value)}
//           isClearable
//         />
//         <Select
//           options={cities}
//           placeholder="City"
//           onChange={(value) => handleFilterChange("city", value)}
//           isClearable
//         />
//       </div>

//       {/* Content Section: Split into Profiles (70%) and Ads (30%) */}
//       <div className="content-split">
//         {/* Profiles Section */}
//         <div className="profiles-section">
//           {filteredStartups.map((startup) => (
//             <StartupCard key={startup.id} {...startup} />
//           ))}
//           {filteredStartups.length === 0 && <p>No startups match the selected filters.</p>}
//         </div>

//         {/* Ads Section */}
//         <div className="ads-section">
//           <h3>Sponsored Ads</h3>
//           <div className="ad-box">Google Ad 1</div>
//           <div className="ad-box">Google Ad 2</div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Startup;


// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom"; // Import useNavigate
// import "./Startup.css";
// import Select from "react-select"; // Import react-select
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faStar, faStarHalfAlt } from "@fortawesome/free-solid-svg-icons";
// import { faStar as faStarEmpty } from "@fortawesome/free-regular-svg-icons";

// // Dropdown options for filters
// const industries = ["Technology", "Healthcare", "Education", "Finance", "Retail"].map((industry) => ({
//   value: industry,
//   label: industry,
// }));

// const sectorTypes = [
//   { value: "Private", label: "Private Sector" },
//   { value: "Public", label: "Public Sector" },
//   { value: "NonProfit", label: "Non-profit" },
// ];

// const states = ["California", "Texas", "New York"].map((state) => ({
//   value: state,
//   label: state,
// }));

// const cities = ["Los Angeles", "Houston", "New York City"].map((city) => ({
//   value: city,
//   label: city,
// }));

// // Startup data
// const startups = [
//   {
//     id: 1,
//     logo: "/images/logo-startup1.jpg",
//     name: "NANO NEXUS LIMITED",
//     stage: "Ideation",
//     rating: 4.5,
//     location: "California",
//     category: "Technology",
//     dpiitVerified: true,
//     sector: "Private",
//   },
//   {
//     id: 2,
//     logo: "/images/logo-startup2.jpg",
//     name: "Qumir Nano",
//     stage: "Early Traction",
//     rating: 3,
//     location: "Texas",
//     category: "Healthcare",
//     dpiitVerified: false,
//     sector: "Public",
//   },
//   {
//     id: 3,
//     logo: "/images/logo-startup3.jpg",
//     name: "EduConnect",
//     stage: "Growth",
//     rating: 4,
//     location: "New York",
//     category: "Education",
//     dpiitVerified: true,
//     sector: "NonProfit",
//   },
//   {
//     id: 4,
//     logo: "/images/logo-startup4.jpg",
//     name: "FinTech Innovations",
//     stage: "Expansion",
//     rating: 4.8,
//     location: "California",
//     category: "Finance",
//     dpiitVerified: true,
//     sector: "Private",
//   },
// ];

// // Component to display rating stars
// const renderRatingStars = (rating) => {
//   const fullStars = Math.floor(rating);
//   const halfStar = rating % 1 !== 0 ? <FontAwesomeIcon icon={faStarHalfAlt} className="half-star" /> : null;
//   const emptyStars = 5 - Math.ceil(rating);

//   return (
//     <div className="rating">
//       {Array(fullStars)
//         .fill()
//         .map((_, i) => (
//           <FontAwesomeIcon key={i} icon={faStar} className="full-star" />
//         ))}
//       {halfStar}
//       {Array(emptyStars)
//         .fill()
//         .map((_, i) => (
//           <FontAwesomeIcon key={i} icon={faStarEmpty} className="empty-star" />
//         ))}
//     </div>
//   );
// };

// // Component for each startup card
// const StartupCard = ({ logo, name, stage, rating, location, category, id, navigate }) => (
//   <div className="startup-card" onClick={() => navigate(`/startup/${id}`)}> {/* Navigate on click */}
//     <img src={logo} alt={`${name} logo`} className="startup-logo" />
//     <div className="startup-info">
//       <h3>{name}</h3>
//       <p>{stage}</p>
//       <p>{location}</p>
//       {renderRatingStars(rating)}
//       <p className="category">{category}</p>
//     </div>
//   </div>
// );

// // Main Startup component
// const Startup = () => {
//   const navigate = useNavigate(); // Use navigate hook
//   const [filters, setFilters] = useState({
//     sortBy: "",
//     rating: 0,
//     dpiitVerified: false,
//     industry: null,
//     sector: null,  // Add sector filter
//     state: null,
//     city: null,
//   });

//   // Handle filter changes
//   const handleFilterChange = (filterKey, value) => {
//     setFilters((prevFilters) => ({
//       ...prevFilters,
//       [filterKey]: value,
//     }));
//   };

//   // Filter startups based on applied filters
//   const filteredStartups = startups.filter(
//     (startup) =>
//       (filters.rating === 0 || startup.rating >= filters.rating) &&
//       (filters.dpiitVerified === false || startup.dpiitVerified === filters.dpiitVerified) &&
//       (filters.industry === null || startup.category === filters.industry.value) &&
//       (filters.sector === null || startup.sector === filters.sector.value) &&  // Filter by sector
//       (filters.state === null || startup.location === filters.state.value) &&
//       (filters.city === null || startup.location === filters.city.value)
//   );

//   return (
//     <div className="startup-container">
//       {/* Filters Section */}
//       <div className="filters-container">
//         <select onChange={(e) => handleFilterChange("sortBy", e.target.value)}>
//           <option value="">Sort By</option>
//           <option value="rating">Rating</option>
//           <option value="name">Name</option>
//         </select>

//         <select onChange={(e) => handleFilterChange("rating", parseFloat(e.target.value))}>
//           <option value="0">All Ratings</option>
//           <option value="3.5">3.5+</option>
//           <option value="4.0">4.0+</option>
//           <option value="4.5">4.5+</option>
//           <option value="5.0">5.0+</option>
//         </select>

//         <label>
//           DPIIT Verified
//           <input
//             type="checkbox"
//             onChange={(e) => handleFilterChange("dpiitVerified", e.target.checked)}
//           />
//         </label>

//         {/* Searchable Dropdowns */}
//         <Select
//           options={industries}
//           placeholder="Industry"
//           onChange={(value) => handleFilterChange("industry", value)}
//           isClearable
//         />
//         <Select
//           options={sectorTypes}
//           placeholder="Sector"
//           onChange={(value) => handleFilterChange("sector", value)}  // Handle sector change
//           isClearable
//         />
//         <Select
//           options={states}
//           placeholder="State"
//           onChange={(value) => handleFilterChange("state", value)}
//           isClearable
//         />
//         <Select
//           options={cities}
//           placeholder="City"
//           onChange={(value) => handleFilterChange("city", value)}
//           isClearable
//         />
//       </div>

//       {/* Content Section: Split into Profiles (70%) and Ads (30%) */}
//       <div className="content-split">
//         {/* Profiles Section */}
//         <div className="profiles-section">
//           {filteredStartups.map((startup) => (
//             <StartupCard key={startup.id} {...startup} navigate={navigate} />
//           ))}
//           {filteredStartups.length === 0 && <p>No startups match the selected filters.</p>}
//         </div>

//         {/* Ads Section */}
//         <div className="ads-section">
//           <h3>Sponsored Ads</h3>
//           <div className="ad-box">Google Ad 1</div>
//           <div className="ad-box">Google Ad 2</div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Startup;


import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "./Startup.css";
import Select from "react-select"; // Import react-select
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faStarHalfAlt } from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarEmpty } from "@fortawesome/free-regular-svg-icons";

// Dropdown options for filters
const industries = ["Technology", "Healthcare", "Education", "Finance", "Retail"].map((industry) => ({
  value: industry,
  label: industry,
}));

const sectorTypes = [
  { value: "Private", label: "Private Sector" },
  { value: "Public", label: "Public Sector" },
  { value: "NonProfit", label: "Non-profit" },
];

const states = ["California", "Texas", "New York"].map((state) => ({
  value: state,
  label: state,
}));

const cities = ["Los Angeles", "Houston", "New York City"].map((city) => ({
  value: city,
  label: city,
}));

// Startup data
const startups = [
  {
    id: 1,
    logo: "/images/bm.jpg",
    name: "NANO NEXUS LIMITED",
    stage: "Ideation",
    rating: 4.5,
    location: "California",
    category: "Technology",
    dpiitVerified: true,
    sector: "Private",
  },
  {
    id: 2,
    logo: "/images/bm.jpg",
    name: "Qumir Nano",
    stage: "Early Traction",
    rating: 3,
    location: "Texas",
    category: "Healthcare",
    dpiitVerified: false,
    sector: "Public",
  },
  {
    id: 3,
    logo: "/images/bm.jpg",
    name: "EduConnect",
    stage: "Growth",
    rating: 4,
    location: "New York",
    category: "Education",
    dpiitVerified: true,
    sector: "NonProfit",
  },
  {
    id: 4,
    logo: "/images/bm.jpg",
    name: "FinTech Innovations",
    stage: "Expansion",
    rating: 4.8,
    location: "California",
    category: "Finance",
    dpiitVerified: true,
    sector: "Private",
  },
];

// Component to display rating stars
const renderRatingStars = (rating) => {
  const fullStars = Math.floor(rating);
  const halfStar = rating % 1 !== 0 ? <FontAwesomeIcon icon={faStarHalfAlt} className="half-star" /> : null;
  const emptyStars = 5 - Math.ceil(rating);

  return (
    <div className="rating">
      {Array(fullStars)
        .fill()
        .map((_, i) => (
          <FontAwesomeIcon key={i} icon={faStar} className="full-star" />
        ))}
      {halfStar}
      {Array(emptyStars)
        .fill()
        .map((_, i) => (
          <FontAwesomeIcon key={i} icon={faStarEmpty} className="empty-star" />
        ))}
    </div>
  );
};

// Component for each startup card
const StartupCard = ({ logo, name, stage, rating, location, category, id, navigate }) => (
  <div className="startup-card" onClick={() => navigate(`/startup/${id}`)}> {/* Navigate on click */}
    <img src={logo} alt={`${name} logo`} className="startup-logo" />
    <div className="startup-info">
      <h3>{name}</h3>
      <p>{stage}</p>
      <p>{location}</p>
      {renderRatingStars(rating)}
      <p className="category">{category}</p>
    </div>
  </div>
);

// Main Startup component
const Startup = () => {
  const navigate = useNavigate(); // Use navigate hook
  const [filters, setFilters] = useState({
    sortBy: "",
    rating: 0,
    dpiitVerified: false,
    industry: null,
    sector: null,  // Add sector filter
    state: null,
    city: null,
  });

  // Handle filter changes
  const handleFilterChange = (filterKey, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterKey]: value,
    }));
  };

  // Filter startups based on applied filters
  const filteredStartups = startups.filter(
    (startup) =>
      (filters.rating === 0 || startup.rating >= filters.rating) &&
      (filters.dpiitVerified === false || startup.dpiitVerified === filters.dpiitVerified) &&
      (filters.industry === null || startup.category === filters.industry.value) &&
      (filters.sector === null || startup.sector === filters.sector.value) &&  // Filter by sector
      (filters.state === null || startup.location === filters.state.value) &&
      (filters.city === null || startup.location === filters.city.value)
  );

  return (
    <div className="startup-container">
      {/* Filters Section */}
      <div className="banner85">Startup </div>
      <div className="filters-container">
        <select onChange={(e) => handleFilterChange("sortBy", e.target.value)}>
          <option value="">Sort By</option>
          <option value="rating">Rating</option>
          <option value="name">Name</option>
        </select>

        <select onChange={(e) => handleFilterChange("rating", parseFloat(e.target.value))}>
          <option value="0">All Ratings</option>
          <option value="3.5">3.5+</option>
          <option value="4.0">4.0+</option>
          <option value="4.5">4.5+</option>
          <option value="5.0">5.0+</option>
        </select>

        <label>
          DPIIT Verified
          <input
            type="checkbox"
            onChange={(e) => handleFilterChange("dpiitVerified", e.target.checked)}
          />
        </label>

        {/* Searchable Dropdowns */}
        <Select
          options={industries}
          placeholder="Industry"
          onChange={(value) => handleFilterChange("industry", value)}
          isClearable
        />
        <Select
          options={sectorTypes}
          placeholder="Sector"
          onChange={(value) => handleFilterChange("sector", value)}  // Handle sector change
          isClearable
        />
        <Select
          options={states}
          placeholder="State"
          onChange={(value) => handleFilterChange("state", value)}
          isClearable
        />
        <Select
          options={cities}
          placeholder="City"
          onChange={(value) => handleFilterChange("city", value)}
          isClearable
        />
      </div>

      {/* Content Section: Split into Profiles (70%) and Ads (30%) */}
      <div className="content-split">
        {/* Profiles Section */}
        <div className="profiles-section">
          {filteredStartups.map((startup) => (
            <StartupCard key={startup.id} {...startup} navigate={navigate} />
          ))}
          {filteredStartups.length === 0 && <p>No startups match the selected filters.</p>}
        </div>

        {/* Ads Section */}
        <div className="ads-section">
          <h3>Sponsored Ads</h3>
          <div className="ad-box">Google Ad 1</div>
          <div className="ad-box">Google Ad 2</div>
        </div>
      </div>
    </div>
  );
};

export default Startup;
