import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faPencilAlt, faIdBadge, faUserCircle, faComments } from '@fortawesome/free-solid-svg-icons';
import './Kairaa.css';
import { Link } from 'react-router-dom';

import { motion } from "framer-motion";
import { FaLightbulb, FaRocket, FaIndustry, FaDollarSign, FaChartLine, FaChalkboardTeacher, FaUsers, FaUserGraduate, FaHandshake, FaAward, FaHandsHelping } from "react-icons/fa";


const Kairaa = () => {
  const [activeTab, setActiveTab] = useState('startup');
  const [activeCard, setActiveCard] = useState(null);

  // Card details
  const cards = [
    { id: 1, title: 'Industry Alliances', content: 'Bringing together stakeholders from various sectors to create opportunities for cross-collaboration.', additionalContent: 'More details about Industry Alliances.' },
    { id: 2, title: 'Dynamic Networking', content: 'Enabling effective networking with industry peers and experts across geographical regions.', additionalContent: 'More details about Dynamic Networking.' },
    { id: 3, title: 'Enhanced Visibility', content: 'Increase your business\'s visibility within the industry with strategic alliances.', additionalContent: 'More details about Enhanced Visibility.' },
    { id: 4, title: 'Personalized ID', content: 'Get your personalized identification number for easy recognition in industry events and platforms.', additionalContent: 'More details about Personalized ID.' },
  ];
  const tabVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
  };
  const cardVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
  };
  // Function to handle flip card click
  const handleCardClick = (id) => {
    setActiveCard(activeCard === id ? null : id);
  };

  // Function to handle tab click
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // Slideshow settings
  const slideshowSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className="x003-kairaa-container">
      {/* Community Section */}
      <div className="x003-community-section">
        <div className="x003-community-banner">
          <h2>"Connect, Discover, & Achieve"</h2>
          <p>
            
Startup Connect is a vibrant platform designed to bring together entrepreneurs, investors, mentors, and policymakers, fostering collaboration and driving innovation. It serves as a launchpad for startups to showcase their ideas, secure funding, and gain expert guidance while providing investors access to high-potential opportunities and mentors a chance to share their expertise. With its dynamic ecosystem, Startup Connect empowers connections, drives entrepreneurial growth, and creates an environment where ideas transform into impactful ventures. Join the movement to shape the future of innovation and unlock endless possibilities.
          </p>
          <Link to="/aboutus" style={{ textDecoration: 'none' }} >
          <button className="x003-btn-know-more">Know more</button>
          </Link>
          <div className="x003-user-stats">
            <p>The No. of Users Registered</p>
            <Link to='/SearchComponent' style={{textDecoration:'none'}}>
           <button className="x003-btn-browse-network" >Browse Network </button>
           </Link>
          </div>
        </div>
      </div><br></br>

      {/* Flip Cards Section */}
      <div className="x003-flip-cards-section">
        <div className="x003-grid-container">
          {cards.map((card) => (
            <div key={card.id} className={`x003-flip-card ${activeCard === card.id ? 'x003-active' : ''}`} onClick={() => handleCardClick(card.id)}>
              <div className="x003-flip-card-inner">
                <div className="x003-flip-card-front">
                  <h2>{card.id.toString().padStart(2, '0')}</h2>
                  <p>{card.title}</p>
                </div>
                <div className="x003-flip-card-back">
                  <p>{card.content}</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Active Card Content */}
        {activeCard && (
          <div className="x003-active-card-content">
            <h2>{cards[activeCard - 1].title}</h2>
            <p>{cards[activeCard - 1].content}</p>
            <p>{cards[activeCard - 1].additionalContent}</p>
          </div>
        )}
      </div><br></br><br></br>

      {/* How It Works Section */}
      <section className="x003-roadmap-section">
  <h2>How it Works</h2>
  <div className="x003-roadmap-container">
    <div className="x003-roadmap-step">
      <div className="x003-roadmap-icon-circle">
        <FontAwesomeIcon icon={faUserPlus} className="x003-roadmap-icon" />
      </div>
      <p>Sign-up</p>
      <span>Select profile type</span>
    </div>
    <div className="x003-roadmap-connector"></div>
    <div className="x003-roadmap-step">
      <div className="x003-roadmap-icon-circle">
        <FontAwesomeIcon icon={faPencilAlt} className="x003-roadmap-icon" />
      </div>
      <p>Add your details</p>
    </div>
    <div className="x003-roadmap-connector"></div>
    <div className="x003-roadmap-step">
      <div className="x003-roadmap-icon-circle">
        <FontAwesomeIcon icon={faIdBadge} className="x003-roadmap-icon" />
      </div>
      <p>Application ID</p>
    </div>
    <div className="x003-roadmap-connector"></div>
    <div className="x003-roadmap-step">
      <div className="x003-roadmap-icon-circle">
        <FontAwesomeIcon icon={faUserCircle} className="x003-roadmap-icon" />
      </div>
      <p>Complete public profile</p>
  
    </div>
    <div className="x003-roadmap-connector"></div>
    <div className="x003-roadmap-step">
      <div className="x003-roadmap-icon-circle">
        <FontAwesomeIcon icon={faComments} className="x003-roadmap-icon" />
      </div>
      <p>Start connecting!</p>
    </div>
  </div>
</section>
<motion.section
  className="ecosystem-stakeholders-section"
  initial={{ opacity: 0 }}
  animate={{ opacity: 1 }}
  transition={{ duration: 1 }}
>
  {/* Header Section */}
  <motion.h2
    className="section-title"
    initial={{ y: -20, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    transition={{ duration: 0.6 }}
  >
    Ecosystem Stakeholders
  </motion.h2>
  <motion.p
    className="section-description"
    initial={{ y: -20, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    transition={{ duration: 0.8 }}
  >
    Startup Connect empowers stakeholders—including startups, investors, and mentors—fostering collaboration and growth.
    Discover how each group plays a vital role in shaping the entrepreneurial ecosystem.
  </motion.p>

  {/* Tab Buttons */}
  <motion.div
    className="tab-buttons"
    variants={tabVariants}
    initial="hidden"
    animate="visible"
  >
    <button
      className={`tab-button ${activeTab === "startup" ? "active-tab" : ""}`}
      onClick={() => handleTabClick("startup")}
    >
      Startup
    </button>
    <button
      className={`tab-button ${activeTab === "investor" ? "active-tab" : ""}`}
      onClick={() => handleTabClick("investor")}
    >
      Investor
    </button>
    <button
      className={`tab-button ${activeTab === "mentor" ? "active-tab" : ""}`}
      onClick={() => handleTabClick("mentor")}
    >
      Mentor
    </button>
  </motion.div>

  {/* Content for Tabs */}
  <motion.div
    className="content-container"
    variants={tabVariants}
    initial="hidden"
    animate="visible"
  >
    {/* Startup Content */}
    {activeTab === "startup" && (
      <motion.div
        className="startup-content slide-animation"
        initial="hidden"
        animate="visible"
      >
        <div className="slider-container">
          <Slider {...slideshowSettings}>
            <motion.div className="card" variants={cardVariants}>
              <FaLightbulb size={50} />
              <h3>Innovative Products</h3>
              <p>
                Startups focus on developing unique solutions to solve real-world problems through innovation and creativity.
              </p>
            </motion.div>
            <motion.div className="card" variants={cardVariants}>
              <FaRocket size={50} />
              <h3>Scaling Opportunities</h3>
              <p>
                Access mentorship, funding, and partnerships to scale your business to greater heights.
              </p>
            </motion.div>
            <motion.div className="card" variants={cardVariants}>
              <FaIndustry size={50} />
              <h3>Market Disruption</h3>
              <p>
                Redefine industries by introducing groundbreaking ideas and changing traditional methods.
              </p>
            </motion.div>
            <motion.div className="card" variants={cardVariants}>
              <FaAward size={50} />
              <h3>Recognition and Visibility</h3>
              <p>
                Gain recognition in the startup ecosystem by showcasing your innovations and achievements.
              </p>
            </motion.div>
          </Slider>
        </div>
      </motion.div>
    )}

    {/* Investor Content */}
    {activeTab === "investor" && (
      <motion.div
        className="investor-content slide-animation"
        initial="hidden"
        animate="visible"
      >
        <div className="slider-container">
          <Slider {...slideshowSettings}>
            <motion.div className="card" variants={cardVariants}>
              <FaDollarSign size={50} />
              <h3>High ROI Potential</h3>
              <p>
                Support startups with significant growth potential and achieve impressive returns on your investments.
              </p>
            </motion.div>
            <motion.div className="card" variants={cardVariants}>
              <FaChartLine size={50} />
              <h3>Portfolio Diversification</h3>
              <p>
                Strengthen your portfolio by investing across various sectors and industries.
              </p>
            </motion.div>
            <motion.div className="card" variants={cardVariants}>
              <FaChalkboardTeacher size={50} />
              <h3>Mentorship Opportunities</h3>
              <p>
                Share your expertise and insights with startups to help them achieve success and growth.
              </p>
            </motion.div>
            <motion.div className="card" variants={cardVariants}>
              <FaHandshake size={50} />
              <h3>Collaborative Ventures</h3>
              <p>
                Partner with startups for long-term success through shared goals and mutual benefits.
              </p>
            </motion.div>
          </Slider>
        </div>
      </motion.div>
    )}

    {/* Mentor Content */}
    {activeTab === "mentor" && (
      <motion.div
        className="mentor-content slide-animation"
        initial="hidden"
        animate="visible"
      >
        <div className="slider-container">
          <Slider {...slideshowSettings}>
            <motion.div className="card" variants={cardVariants}>
              <FaUsers size={50} />
              <h3>Strategic Guidance</h3>
              <p>
                Help startups refine their business strategies and navigate challenges effectively.
              </p>
            </motion.div>
            <motion.div className="card" variants={cardVariants}>
              <FaUserGraduate size={50} />
              <h3>Skill Development</h3>
              <p>
                Equip entrepreneurs with the necessary skills for sustainable success and innovation.
              </p>
            </motion.div>
            <motion.div className="card" variants={cardVariants}>
              <FaHandshake size={50} />
              <h3>Networking Support</h3>
              <p>
                Connect startups with valuable industry contacts to expand their reach and opportunities.
              </p>
            </motion.div>
            <motion.div className="card" variants={cardVariants}>
              <FaHandsHelping size={50} />
              <h3>Building Communities</h3>
              <p>
                Foster a thriving community of startups and entrepreneurs by sharing your experience.
              </p>
            </motion.div>
          </Slider>
        </div>
      </motion.div>
    )}
  </motion.div>
</motion.section>


    </div>
  );
};

export default Kairaa;
