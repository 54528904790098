import React, { useState, useEffect, useRef, useMemo} from 'react';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import './homepage.css';
import { FaPlaneArrival } from "react-icons/fa6";
import 'react-slideshow-image/dist/styles.css'; // Import default styles

import { FaCertificate, FaArrowRight } from "react-icons/fa"; // Import advanced icons


  const Homepage = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(true); // Popup is open by default
    const [startupImage, setStartupImage] = useState(""); // To dynamically set the startup image
    const [verifiedUsersCount, setVerifiedUsersCount] = useState(0);
    const targetCount = 120; // Example target count
  
    useEffect(() => {
      // Animation logic to count up to the target count
      const timer = setInterval(() => {
        setVerifiedUsersCount((prevCount) => {
          if (prevCount < targetCount) {
            return prevCount + 1; // Increment count
          } else {
            clearInterval(timer); // Stop once target is reached
            return prevCount;
          }
        });
      }, 10);

    return () => clearInterval(timer); // Clean up the interval on component unmount
  }, []);
  
    const containerVariants = {
      hidden: { opacity: 0, x: "100%" },
      visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
      exit: { opacity: 0, x: "100%", transition: { duration: 0.3 } },
    };
  
    const closePopup = () => {
      setIsPopupOpen(false); // Close the popup
    };
  
    // Simulate fetching startup image or any other data
    useEffect(() => {
      // This could be replaced with an API call to fetch startup data
      setStartupImage("https://th.bing.com/th/id/R.d02d6da26ecb926566f468fd0c63246b?rik=aewVinduurJNnQ&riu=http%3a%2f%2fthumbs.dreamstime.com%2fz%2fnew-arrivals-stock-arrival-concept-words-coming-out-cardboard-box-36130700.jpg&ehk=wCOYowubaFdxEdA4Spj4OLlQ%2bM%2bMEiKo5ZkTDF4IY6A%3d&risl=&pid=ImgRaw&r=0"); // Set an image URL
    }, []); // Only run on component mount
  


  const sections = [
    { title: 'Connect with Startups', description: 'Investors and mentors can explore and connect with innovative startups.', icon: '🔗' },
    { title: 'Mentorship Programs', description: 'Empower startups with industry insights and guidance.', icon: '🎓' },
    { title: 'Investment Opportunities', description: 'Discover startups aligned with your interests for collaboration.', icon: '💼' },
    { title: 'Customized Services', description: 'Access tailored resources to scale your startup effectively.', icon: '⚙️' },
  ];
  //slider code below 

  const mainSliderImages = useMemo(() => [
    'https://res.cloudinary.com/dmoweu5mh/image/upload/v1734090735/360_F_238405533_yyX4K55OmH2LiM7LQ01ITn6kl0b6J6K8_q0gmgg.jpg', // Main slider images
    'https://res.cloudinary.com/dmoweu5mh/image/upload/v1734079810/WhatsApp_Image_2024-12-13_at_12.30.57_PM_rdjqvy.jpg',
    'https://res.cloudinary.com/dmoweu5mh/image/upload/v1734093379/best-investment-PowerPoint-template_1_gxkqx4.jpg',
    'https://res.cloudinary.com/dmoweu5mh/image/upload/v1734090880/Strategies-for-winning-investors-in-Your-Business-01-scaled_fjw9ga.jpg',
    'https://res.cloudinary.com/dmoweu5mh/image/upload/v1734090997/startup-company-scaled_me8pvv.jpg'
  ], []); // No dependencies, this array will remain the same

  const boxSliderImages = useMemo(() => [
    [ 'https://res.cloudinary.com/dmoweu5mh/image/upload/v1734090997/startup-company-scaled_me8pvv.jpg','https://res.cloudinary.com/dmoweu5mh/image/upload/v1734090735/360_F_238405533_yyX4K55OmH2LiM7LQ01ITn6kl0b6J6K8_q0gmgg.jpg','https://res.cloudinary.com/dmoweu5mh/image/upload/v1734085749/WhatsApp_Image_2024-12-13_at_3.54.33_PM_tdrtor.jpg','https://res.cloudinary.com/dmoweu5mh/image/upload/v1734093379/best-investment-PowerPoint-template_1_gxkqx4.jpg','https://res.cloudinary.com/dmoweu5mh/image/upload/v1734090880/Strategies-for-winning-investors-in-Your-Business-01-scaled_fjw9ga.jpg'],
    [ 'https://res.cloudinary.com/dmoweu5mh/image/upload/v1734090880/Strategies-for-winning-investors-in-Your-Business-01-scaled_fjw9ga.jpg','https://res.cloudinary.com/dmoweu5mh/image/upload/v1734090997/startup-company-scaled_me8pvv.jpg','https://res.cloudinary.com/dmoweu5mh/image/upload/v1734090735/360_F_238405533_yyX4K55OmH2LiM7LQ01ITn6kl0b6J6K8_q0gmgg.jpg','https://res.cloudinary.com/dmoweu5mh/image/upload/v1734085749/WhatsApp_Image_2024-12-13_at_3.54.33_PM_tdrtor.jpg','https://res.cloudinary.com/dmoweu5mh/image/upload/v1734093379/best-investment-PowerPoint-template_1_gxkqx4.jpg'],
    [ 'https://res.cloudinary.com/dmoweu5mh/image/upload/v1734085749/WhatsApp_Image_2024-12-13_at_3.54.33_PM_tdrtor.jpg','https://res.cloudinary.com/dmoweu5mh/image/upload/v1734093379/best-investment-PowerPoint-template_1_gxkqx4.jpg','https://res.cloudinary.com/dmoweu5mh/image/upload/v1734090880/Strategies-for-winning-investors-in-Your-Business-01-scaled_fjw9ga.jpg','https://res.cloudinary.com/dmoweu5mh/image/upload/v1734090997/startup-company-scaled_me8pvv.jpg','https://res.cloudinary.com/dmoweu5mh/image/upload/v1734090735/360_F_238405533_yyX4K55OmH2LiM7LQ01ITn6kl0b6J6K8_q0gmgg.jpg'],
    [ 'https://res.cloudinary.com/dmoweu5mh/image/upload/v1734093379/best-investment-PowerPoint-template_1_gxkqx4.jpg','https://res.cloudinary.com/dmoweu5mh/image/upload/v1734090880/Strategies-for-winning-investors-in-Your-Business-01-scaled_fjw9ga.jpg','https://res.cloudinary.com/dmoweu5mh/image/upload/v1734090997/startup-company-scaled_me8pvv.jpg','https://res.cloudinary.com/dmoweu5mh/image/upload/v1734090735/360_F_238405533_yyX4K55OmH2LiM7LQ01ITn6kl0b6J6K8_q0gmgg.jpg','https://res.cloudinary.com/dmoweu5mh/image/upload/v1734085749/WhatsApp_Image_2024-12-13_at_3.54.33_PM_tdrtor.jpg']
  ], []); // No dependencies, this array will remain the same// No dependencies, this array will remain the same

  const [mainSliderIndex, setMainSliderIndex] = useState(0);
  const [boxSliderIndices, setBoxSliderIndices] = useState([0, 0, 0, 0]);

  // Auto-slide effect for the main slider
  useEffect(() => {
    const interval = setInterval(() => {
      setMainSliderIndex((prev) => (prev === mainSliderImages.length - 1 ? 0 : prev + 1));
    }, 3000); // Slide every 3 seconds

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [mainSliderImages.length]);

  // Auto-slide effect for each box slider
  useEffect(() => {
    const intervals = boxSliderImages.map((_, index) => {
      return setInterval(() => {
        setBoxSliderIndices((prev) => {
          const updated = [...prev];
          updated[index] = (updated[index] + 1) % boxSliderImages[index].length;
          return updated;
        });
      }, 2000); // Slide every 2 seconds for each slider
    });

    // Cleanup function to clear intervals
    return () => intervals.forEach(clearInterval);
  }, [boxSliderImages]);


  // Initial states for numbers
  const [investorsCount, setInvestorsCount] = useState(0);
  const [startupsCount, setStartupsCount] = useState(0);
  const [mentorsCount, setMentorsCount] = useState(0);
  const [hasAnimated, setHasAnimated] = useState(false); // To track if the animation has run

  // Final counts (simulating API data)
  const finalInvestorsCount = 60;
  const finalStartupsCount = 390;
  const finalMentorsCount = 120;

  // Ref to target the container
  const containerRef = useRef(null);

  // Number animation function
  const animateNumber = (setStateFunction, finalValue, duration) => {
    let startNumber = 0;
    const incrementTime = 50; // Update every 10ms
    const step = (finalValue / (duration / incrementTime)); // Incremental step

    const timer = setInterval(() => {
      startNumber += step;
      if (startNumber >= finalValue) {
        startNumber = finalValue;
        clearInterval(timer);
      }
      setStateFunction(Math.floor(startNumber));
    }, incrementTime);
  };

  // Intersection Observer to trigger the animation when the container comes into view
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !hasAnimated) {
        // Start animation if the container is in view and hasn't animated yet
        animateNumber(setInvestorsCount, finalInvestorsCount, 2000); // 1 second animation
        animateNumber(setStartupsCount, finalStartupsCount, 2000);   // 1 second animation
        animateNumber(setMentorsCount, finalMentorsCount, 2000);     // 1 second animation
        setHasAnimated(true); // Prevent re-triggering the animation
      }
    }, { threshold: 0.5 }); // Trigger when 50% of the container is in view

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

  }, [hasAnimated]); // Dependency on `hasAnimated` to run the animation only once
  //email subscription code
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Send email to backend
    try {
      const response = await fetch("/api/subscribe", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setStatus("Subscribed successfully!");
      } else {
        setStatus("Subscription failed. Try again.");
      }
    } catch (error) {
      setStatus("Error occurred. Try again later.");
    }
    
  }
  return (
    <div className="home-container">
      <motion.div
      className="startup-connect-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <motion.div
        className="startup-connect-header"
        initial={{ y: -100 }}
        animate={{ y: 0 }}
        transition={{ type: "spring", stiffness: 100 }}
      >
        <h1>Welcome to Startup Connect</h1>
      </motion.div>

      <motion.div
        className="startup-connect-body"
        initial={{ x: -100 }}
        animate={{ x: 0 }}
        transition={{ type: "spring", stiffness: 120 }}
      >
        <p>
        Startup Connect helps startups, investors, and mentors connect for collaboration, funding, and guidance to drive growth and success. By fostering meaningful partnerships and offering strategic insights, it empowers startups to scale, innovate, and thrive in a competitive market.
        </p>
      </motion.div>

      <motion.div
        className="startup-connect-footer"
        initial={{ scale: 0.5 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.5 }}
      >
      </motion.div>
    </motion.div>
    <div className="container">
      <div className="top-section">
        <div className="main-slider">
          <img src={mainSliderImages[mainSliderIndex]} alt="Main Slider" />
        </div>
        <div className="box-sliders">
          {boxSliderImages.map((slider, index) => (
            <div key={index} className="box-slider">
              <img src={slider[boxSliderIndices[index]]}  alt="sliders" />
            </div>
            
          ))}
        </div>
      </div>
    </div>
    <div className="homepage-container-hm22">
      {isPopupOpen && (
        <motion.div
          className="popup-overlay-hm22"
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={containerVariants}
          onClick={closePopup}
        >
          <motion.div
            className="popup-container-hm22 advanced-popup-hm22"
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
          >
            <button className="close-button-hm22" onClick={closePopup}>
              &times;
            </button>
            <div className="popup-content-hm22">
            <h2 className="popup-title-hm22">
                <FaPlaneArrival /> New Startup Arrival</h2>
              <div className="startup-image-container-hm22">
                {startupImage && <img src={startupImage} alt="New Startup" className="startup-image-hm22" />}
              </div>
              <p className="popup-description-hm22">Check out the latest innovative startup to join our platform.</p>
            </div>
          </motion.div>
        </motion.div>
      )}
    </div>
  
      <center>
        {/*key initiative*/}
        <div className="key-initiative">
  <h2>Key Initiatives</h2>
  <p>
    Our Startup Connect platform connects entrepreneurs, startups, investors, and mentors for collaboration and networking. Users can create profiles, showcase their businesses, and connect with others based on their needs. Startups can attract investors and seek mentorship, while investors explore potential opportunities, and mentors offer guidance. Our app features premium subscription plans for added services like exclusive networking and advanced tools.
  </p>
</div>
    </center>
 
    <div className="homepage-container">
      <header className="homepage-header">
        <h1 className="homepage-title">Services We Offer</h1>
        <p className="homepage-subtitle">
          Empowering startups, investors, and mentors through a vibrant ecosystem of support.
        </p>
      </header>

      <div className="services-container">
        {sections.map((section, index) => (
          <motion.div
            key={index}
            className="service-card"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
          >
            <div className="icon-container">{section.icon}</div>
            <h3 className="service-title">{section.title}</h3>
            <p className="service-description">{section.description}</p>
          </motion.div>
        ))}
      </div>
    </div>
    <div className="dpiit-container-1123">
      <motion.div
        className="dpiit-content-1123"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
      >
        <div className="dpiit-header-1123">
          <FaCertificate className="dpiit-icon-1123" /> {/* Icon before the title */}
          <h1 className="dpiit-title-1123">Explore Opportunities</h1>
        </div>
        <p className="dpiit-description-1123">
          Verify your DPIIT certification with ease and unlock new possibilities.
        </p>
        
        {/* Display the Count of Verified Users */}
        <motion.div
          className="verified-users-count-1123"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <h2 className="verified-users-header-1123">Verified Users </h2>
          <p className="verified-users-count-text-1123">
            <strong>{verifiedUsersCount}</strong> users have successfully verified their DPIIT certification.
          </p>
        </motion.div>

        <Link to="/dpiit2">
          <motion.button
            className="dpiit-button-1123"
            whileHover={{ scale: 1.1, transition: { duration: 0.3 } }}
          >
            Learn More <FaArrowRight className="button-icon-1123" /> {/* Icon inside the button */}
          </motion.button>
        </Link>
      </motion.div>
    </div>
       
    <div className="Users" ref={containerRef}>
      <h2>Our Happy Users</h2>
      {/*happy users container */}
    <center>
      <div className="stats-container">
      <div className="card3">
        <p>No. of Investors</p>
        <span className="count">{investorsCount} +</span>
      </div>
      <div className="card3">
        <p>No. of Startups</p>
        <span className="count">{startupsCount} +</span>
      </div>
      <div className="card3">
        <p>No. of Mentors</p>
        <span className="count">{mentorsCount} +</span>
      </div>
    </div></center><br></br>

    <center>
      <motion.div 
      className="advanced-search-container"
      initial={{ opacity: 0, y: 20 }} 
      animate={{ opacity: 1, y: 0 }} 
      transition={{ duration: 0.8, ease: 'easeOut' }}
    >
      <h2 className="search-title">Who are you looking for?</h2>

      {/* Stats and Browse Network Section */}
      <div className="x003-user-stats">
        <p>The No. of Users Registered</p>
        <Link to="/SearchComponent" style={{ textDecoration: 'none' }}>
          <motion.button
            className="x003-btn-browse-network"
            whileHover={{ scale: 1.1 }}  // Slightly scale up on hover
            whileTap={{ scale: 0.95 }}   // Slightly scale down when clicked
            transition={{ duration: 0.2 }}  // Duration of the animation
          >
            Browse Network
          </motion.button>
        </Link>
      </div>
    </motion.div>
    </center>
    <center>
    <motion.div
  className="cta-card"
  initial={{ opacity: 0, y: 50 }}
  animate={{ opacity: 1, y: 0 }}
  transition={{ duration: 1 }}
>
  <div className="cta-frame">
    <h2>Join the Revolution</h2>
    <p>Sign up today and be a part of the Startup Connect community.</p>
    <div className="cta-buttons">
  <Link to="/StartupRegistration">
    <motion.button
      whileHover={{ scale: 1.1 }}
      className="cta-button"
    >
      Register as a Startup
    </motion.button>
  </Link>

  <Link to="/InvestorRegistration">
    <motion.button
      whileHover={{ scale: 1.1 }}
      className="cta-button"
    >
      Register as an Investor
    </motion.button>
  </Link>

  <Link to="/MentorRegistration">
    <motion.button
      whileHover={{ scale: 1.1 }}
      className="cta-button"
    >
      Register as a Mentor
    </motion.button>
  </Link>
</div>
  </div>
</motion.div>

    </center><br></br>

    {/*email subscription part */}

    <center><div className='email-subscription'>

      <form onSubmit={handleSubmit} style={{ display: "inline-block" }}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email to subscribe"
          required
          style={{
            padding: "10px",
            borderRadius: "10px",
            border: "1px solid black",
            width: "300px",
            fontSize: "16px",
          }}
        /><br></br>
        <br></br>
        <button
          type="submit"
          style={{
            backgroundColor: "red",
            color: "white",
            padding: "10px 20px",
            borderRadius: "10px",
            border: "none",
            marginLeft: "10px",
            fontSize: "16px",
          }}
        >
          Subscribe for updates ✖
        </button>
      </form>
      {status && <p>{status}</p>}
    </div></center>
    </div>
    {/*Social Media contacts */}
    <center><div className='social-media-icons'>
      <h2>Our Social Media Platforms</h2>
    </div>
    <div className='social-icons'>
        <img src="/images/linkedin-rounded-icon.png" alt="linkedin.com"/>
        <img src="/images/insta-rounded-icon.jpg" alt="instagram.com"/>
        <img src="/images/fb-rounded-icon.jpeg" alt="facebook.com"/>
        <img src="/images/twitter-rounded-icon.jpeg" alt="twitter.com"/>
      </div></center>
    </div>
  );
}
export default Homepage;
