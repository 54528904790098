// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
// import './Profile.css';

// const Profile = () => {
//   const [user, setUser] = useState({
//     startupName: "",
//     email: "",
//     mobile: "",
//     funding: "",
//     stage: "",
//     brief: "",
//     location: "",
//     industry: "",
//     sector: "",
//     entityNature: "",
//     udyogAadhaar: "",
//     interestAreas: {},
//     createdAt: "",
//   });

//   const navigate = useNavigate();
//   const useremail = localStorage.getItem('email');
//    const email = "lokesh29@gmail.com";

//   useEffect(() => {
//     const fetchProfile = async () => {
//       try {
//         console.log("Fetching profile for email:", useremail);
//         const response = await axios.post('https://back.startupconnectz.com/api/startups/all/profile', { email });
//         if (response.status === 200) {
//           const profile = response.data;
//           setUser({
//             startupName: profile.startupName || 'N/A',
//             email: profile.email || 'N/A',
//             mobile: profile.mobile || 'N/A',
//             funding: profile.funding || 'Unknown',
//             stage: profile.stage || 'N/A',
//             brief: profile.brief || 'N/A',
//             location: `${profile.city || 'N/A'}, ${profile.state || 'N/A'}`,
//             industry: profile.industry || 'N/A',
//             sector: profile.sector || 'N/A',
//             entityNature: profile.entityNature || 'N/A',
//             udyogAadhaar: profile.udyogAadhaar || 'N/A',
//             interestAreas: profile.interestAreas || {},
//             createdAt: new Date(profile.createdAt || Date.now()).toLocaleDateString(),
//           });
//         } else {
//           console.error('Error: Unexpected response status', response.status);
//         }
//       } catch (error) {
//         console.error('Error fetching profile:', error.message);
//       }
//     };

//     fetchProfile();
//   }, [email]);

//   const backclick = () => {
//     navigate('/dashboard');
//   };

//   return (
//     <div className="profile-container">
//       {/* Header Section */}
//       <div className="profile-header-banner">
//         <h1>Welcome, {user.startupName || "Startup Founder"}!</h1>
//         <p>Here’s an overview of your profile details.</p>
//       </div>

//       {/* Profile Details Section */}
//       <div className="profile-content">
//         <section className="profile-section">
//           <h3>General Information</h3>
//           <p><strong>Startup Name:</strong> {user.startupName}</p>
//           <p><strong>Email:</strong> {user.email}</p>
//           <p><strong>Mobile:</strong> {user.mobile}</p>
//           <p><strong>Funding Type:</strong> {user.funding}</p>
//           <p><strong>Startup Stage:</strong> {user.stage}</p>
//         </section>

//         <section className="profile-section">
//           <h3>Business Information</h3>
//           <p><strong>Industry:</strong> {user.industry}</p>
//           <p><strong>Sector:</strong> {user.sector}</p>
//           <p><strong>Entity Type:</strong> {user.entityNature}</p>
//           <p><strong>Udyog Aadhaar:</strong> {user.udyogAadhaar}</p>
//         </section>

//         <section className="profile-section">
//           <h3>Location</h3>
//           <p><strong>City/State:</strong> {user.location}</p>
//         </section>

//         <section className="profile-section">
//           <h3>Interests</h3>
//           <ul>
//             {Object.entries(user.interestAreas).map(([key, value]) => (
//               <li key={key}>{key}: {value ? 'Yes' : 'No'}</li>
//             ))}
//           </ul>
//         </section>

//         <section className="profile-section">
//           <h3>Other Details</h3>
//           <p><strong>Brief:</strong> {user.brief}</p>
//           <p><strong>Profile Created On:</strong> {user.createdAt}</p>
//         </section>
//       </div>

//       <button className="back-btn" onClick={backclick}>Back to Dashboard</button>
//     </div>
//   );
// };

// export default Profile;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Profile.css';

const Profile = () => {
  const [user, setUser] = useState(null);
  const [userType, setUserType] = useState(null); // To determine if it's a startup, mentor, or investor
  const navigate = useNavigate();
  const token = localStorage.getItem('jwtToken'); // Get JWT token from local storage

  useEffect(() => {
    const fetchProfile = async () => {
      if (!token) {
        console.error('No JWT token found');
        return;
      }
      
      try {
        const response = await axios.get('https://back.startupconnectz.com/api/profiles', {
          headers: {
            Authorization: `Bearer ${token}` // Pass the token in the Authorization header
          }
        });

        if (response.status === 200) {
          const profile = response.data;
          setUserType(profile.userType); // Set the user type (startup, mentor, or investor)

          // Set user based on the user type
          if (profile.userType === "startup") {
            setUser({
              startupName: profile.startupName || 'N/A',
              email: profile.email || 'N/A',
              mobile: profile.mobile || 'N/A',
              funding: profile.funding || 'Unknown',
              stage: profile.stage || 'N/A',
              brief: profile.brief || 'N/A',
              location: `${profile.city || 'N/A'}, ${profile.state || 'N/A'}`,
              industry: profile.industry || 'N/A',
              sector: profile.sector || 'N/A',
              entityNature: profile.entityNature || 'N/A',
              udyogAadhaar: profile.udyogAadhaar || 'N/A',
              interestAreas: profile.interestAreas || {},
              createdAt: new Date(profile.createdAt || Date.now()).toLocaleDateString(),
            });
          } else if (profile.userType === "mentor") {
            setUser({
              mentorName: profile.mentorName || 'N/A',
              email: profile.email || 'N/A',
              mobile: profile.mobile || 'N/A',
              network: profile.network || 'N/A',
              expertise: profile.industry || 'N/A',
              activeMonths: profile.activeMonths || 'N/A',
              successStories: profile.successStories ? "Yes" : "No",
              description: profile.description || 'N/A',
              websiteLink: profile.websiteLink || 'N/A',
              hubProfileLink: profile.hubProfileLink || 'N/A',
            });
          } else if (profile.userType === "investor") {
            setUser({
              contactInfo: {
                name: profile.contactInfo.name || 'N/A',
                email: profile.contactInfo.email || 'N/A',
                phone: profile.contactInfo.phone || 'N/A',
                location: profile.contactInfo.location || 'N/A',
              },
              category: profile.category || 'N/A',
              interest: {
                focusAreas: profile.interest.focusAreas || [],
                investmentStage: profile.interest.investmentStage || 'N/A',
                investmentLevel: profile.interest.investmentLevel || 'N/A',
              },
              profilePicturePath: profile.profilePicturePath || 'N/A',
            });
          }
        } else {
          console.error('Error fetching profile:', response.status);
        }
      } catch (error) {
        console.error('Error fetching profile:', error.message);
      }
    };

    fetchProfile();
  }, [token]); // Dependency on token so it refetches when the token changes

  const backclick = () => {
    navigate('/dashboard');
  };

  if (!user) return <div>Loading...</div>;

  return (
    <div className="profile-container">
      {/* Header Section */}
      <div className="profile-header-banner">
        <h1>Welcome, {user.name || "User"}!</h1>
        <p>Here’s an overview of your profile details.</p>
      </div>

      {/* Profile Details Section */}
      <div className="profile-content">
        {userType === "startup" && (
          <>
            <section className="profile-section">
              <h3>General Information</h3>
              <p><strong>Startup Name:</strong> {user.startupName}</p>
              <p><strong>Email:</strong> {user.email}</p>
              <p><strong>Mobile:</strong> {user.mobile}</p>
              <p><strong>Funding Type:</strong> {user.funding}</p>
              <p><strong>Startup Stage:</strong> {user.stage}</p>
            </section>

            <section className="profile-section">
              <h3>Business Information</h3>
              <p><strong>Industry:</strong> {user.industry}</p>
              <p><strong>Sector:</strong> {user.sector}</p>
              <p><strong>Entity Type:</strong> {user.entityNature}</p>
              <p><strong>Udyog Aadhaar:</strong> {user.udyogAadhaar}</p>
            </section>

            <section className="profile-section">
              <h3>Location</h3>
              <p><strong>City/State:</strong> {user.location}</p>
            </section>

            <section className="profile-section">
              <h3>Interests</h3>
              <ul>
                {Object.entries(user.interestAreas).map(([key, value]) => (
                  <li key={key}>{key}: {value ? 'Yes' : 'No'}</li>
                ))}
              </ul>
            </section>

            <section className="profile-section">
              <h3>Other Details</h3>
              <p><strong>Brief:</strong> {user.brief}</p>
              <p><strong>Profile Created On:</strong> {user.createdAt}</p>
            </section>
          </>
        )}

        {userType === "mentor" && (
          <>
            <section className="profile-section">
              <h3>Mentor Information</h3>
              <p><strong>Mentor Name:</strong> {user.mentorName}</p>
              <p><strong>Email:</strong> {user.email}</p>
              <p><strong>Mobile:</strong> {user.mobile}</p>
              <p><strong>Network:</strong> {user.network}</p>
              <p><strong>Expertise:</strong> {user.expertise}</p>
              <p><strong>Active Months:</strong> {user.activeMonths}</p>
              <p><strong>Success Stories:</strong> {user.successStories}</p>
              <p><strong>Brief:</strong> {user.description}</p>
            </section>

            <section className="profile-section">
              <h3>Other Information</h3>
              <p><strong>Website Link:</strong> {user.websiteLink}</p>
              <p><strong>Hub Profile Link:</strong> {user.hubProfileLink}</p>
            </section>
          </>
        )}

        {userType === "investor" && (
          <>
            <section className="profile-section">
              <h3>Investor Information</h3>
              <p><strong>Name:</strong> {user.contactInfo.name}</p>
              <p><strong>Email:</strong> {user.contactInfo.email}</p>
              <p><strong>Phone:</strong> {user.contactInfo.phone}</p>
              <p><strong>Location:</strong> {user.contactInfo.location}</p>
            </section>

            <section className="profile-section">
              <h3>Investment Details</h3>
              <p><strong>Category:</strong> {user.category}</p>
              <p><strong>Investment Focus Areas:</strong> {user.interest.focusAreas.join(', ')}</p>
              <p><strong>Investment Stage:</strong> {user.interest.investmentStage}</p>
              <p><strong>Investment Level:</strong> {user.interest.investmentLevel}</p>
            </section>

            <section className="profile-section">
              <h3>Profile Picture</h3>
              {user.profilePicturePath ? (
                <img src={`https://back.startupconnectz.com/uploads/${user.profilePicturePath}`} alt="Profile" />
              ) : (
                <p>No profile picture uploaded</p>
              )}
            </section>
          </>
        )}
      </div>

      <button className="back-btn" onClick={backclick}>Back to Dashboard</button>
    </div>
  );
};

export default Profile;
