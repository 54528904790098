import React, { useState } from 'react';
import './Mentorlist.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';
import { Link } from 'react-router-dom';

const citiesByState = {
    AndhraPradesh:[" Visakhapatnam", "Vijayawada", "Tirupati", "Guntur","Rajahmundry"],
    ArunachalPradesh: ["Itanagar", "Tawang", "Pasighat", "Ziro"],
    Assam:["Guwahati", "Dibrugarh", "Jorhat", "Silchar"],
    Bihar:["Patna", "Gaya", "Bhagalpur", "Muzaffarpur"],
    Chhatisgarh:["Raipur", "Bilaspur", "Bhilai", "Korba"],
    Goa:["Panaji", "Margao", "Vasco da Gama", "Mapusa"],
    Gujarat:[" Ahmedabad", "Surat", "Vadodara", "Rajkot"],
    Haryana:["Gurgaon", "Faridabad", "Panipat", "Ambala"],
    HimachalPradesh:["Shimla", "Manali", "Dharamshala", "Solan"],
    Jharkhand:["Ranchi", "Jamshedpur", "Dhanbad", "Bokaro"],
    Karnataka: ["Bangalore", "Mysore", "Mangalore"],
    Kerala:["Thiruvananthapuram", "Kochi", "Kozhikode", "Thrissur"],
    MadhyaPradesh:["Bhopal", "Indore", "Gwalior", "Jabalpur"],
    Maharashtra:[" Mumbai","Pune", "Nagpur"," Nashik"],
    Manipur:["Imphal", "Churachandpur", "Thoubal", "Moirang"],
    Meghalaya:["Shillong", "Tura"," Nongstoin", "Jowai"],
    Mizoram:["Aizawl", "Lunglei", "Champhai"," Serchhip"],
    Nagaland:["Kohima", "Dimapur", "Mokokchung"," Wokha"],
    Odisha:["Bhubaneswar","Cuttack", "Rourkela"," Puri"],
    Punjab:["Chandigarh", "Amritsar", "Ludhiana","Jalandhar"],
    Rajasthan:[" Jaipur", "Udaipur", "Jodhpur", "Ajmer"],
    Sikkim:["Gangtok", "Namchi", "Mangan", "Gyalshing"],
    TamilNadu: ["Chennai", "Coimbatore", "Madurai", "Trichy"],
    Telangana:["Hyderabad", "Warangal"," Nizamabad"," Karimnagar"],
    Tripura:["Agartala", "Udaipur", "Dharmanagar", "Kailashahar"],
    UttarPradesh:[" Lucknow", "Kanpur", "Varanasi", "Agra"],
    Uttarakhand:["Dehradun", "Haridwar"," Nainital"," Roorkee"],
    WestBengal:["Kolkata", "Howrah", "Siliguri", "Durgapur"],
    
    
  };
  
  
const Mentorlist = () => {
  const [mentors] = useState([
    {
      
      name: 'John Doe',
      jobTitle: 'Senior Software Engineer',
      industryExperience: 'Agriculture',
      experienceLevel: 'Expert',
      rating: 2,
      monthsActive: 3,
      state: "Odisha",
      city: " Puri",
      image:  "/images/bm.jpg",
    },
    {
        image: "/images/bm.jpg",
      name: 'Jane Smith',
      jobTitle: 'Project Manager',
      industryExperience: 'IT',
      experienceLevel: 'Expert',
      rating: 3,
      monthsActive: 7,
      state: "Punjab"  ,
      city:"Chandigarh" ,
    },
    {
        image: "/images/bm.jpg",
      name: 'Emily Davis',
      jobTitle: 'UX Designer',
      industryExperience: 'Finance',
      experienceLevel: 'Intermediate',
      rating: 4,
      monthsActive: 5,
      state: "Rajasthan ",
      city: " Jaipur" ,
    },
    {
        image: "/images/bm.jpg",
      name: 'William Wilson',
      jobTitle: 'HR Manager',
      industryExperience: 'Energy',
      experienceLevel: 'Expert',
      rating: 5,
      monthsActive: 40,
      state: "Sikkim ",
      city:"Gangtok" ,
    },
    {
        image: "/images/bm.jpg",
      name: 'Wilson',
      jobTitle: 'HR Manager',
      industryExperience: 'Resource',
      experienceLevel: 'Beginner',
      rating: 5,
      monthsActive: 15
      ,
      state:"TamilNadu" ,
      city: "Chennai",
    },
  ]);

  // State for filter selections
  const [sortBy, setSortBy] = useState('');
  const [experienceLevel, setExperienceLevel] = useState('');
  const [rating, setRating] = useState('');
  const [monthsActive, setMonthsActive] = useState('');
  const [industryExperience, setIndustryExperience] = useState('');
  const [state, setState] =useState('');
  const [city, setCity] = useState('');

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const emptyStars = 5 - fullStars;

    return (
      <div className="rating85">
        {Array(fullStars)
          .fill()
          .map((_, i) => (
            <FontAwesomeIcon key={`full-${i}`} icon={faStarSolid} className="full-star85" />
          ))}
        {Array(emptyStars)
          .fill()
          .map((_, i) => (
            <FontAwesomeIcon key={`empty-${i}`} icon={faStarRegular} className="empty-star85" />
          ))}
      </div>
    );
  };

  // Function to filter mentors based on selected filters
  const getFilteredMentors = () => {
    let filteredMentors = mentors.filter((mentor) => {
      const matchesExperienceLevel = experienceLevel
        ? mentor.experienceLevel.toLowerCase() === experienceLevel.toLowerCase()
        : true;
      const matchesRating = rating ? mentor.rating >= parseFloat(rating) : true;
      const matchesIndustryExperience = industryExperience
        ? mentor.industryExperience.toLowerCase().includes(industryExperience.toLowerCase())
        : true;
      const matchesState = state
        ? mentor.state.toLowerCase().trim() === state.toLowerCase().trim()
        : true;
      const matchesCity = city
        ? mentor.city.toLowerCase().trim() === city.toLowerCase().trim()
        : true;
      const matchesMonthActive = monthsActive
        ? mentor.monthsActive >= parseInt(monthsActive)
        : true;
  
      return (
        matchesExperienceLevel &&
        matchesRating &&
        matchesIndustryExperience &&
        matchesState &&
        matchesCity &&
        matchesMonthActive
      );
    });
  
    // Sort the filtered mentors based on the "Sort By" filter
    if (sortBy === 'name') {
      filteredMentors = filteredMentors.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    } else if (sortBy === 'Rating') {
      filteredMentors = filteredMentors.sort((a, b) => b.rating - a.rating);
    }
  
    return filteredMentors;
  };
  

  return (
    <div className="mentorlist-container85">
      <div className="banner85">Mentor </div>
      <div className="filters-container85">
        <select
          className="filter85"
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value)}
        >
          <option value="">Sort By</option>
          <option value="name">Name</option>
          <option value="Rating">Rating</option>
        </select>
        <select
          className="filter85"
          value={experienceLevel}
          onChange={(e) => setExperienceLevel(e.target.value)}
        >
          <option value="">Experience Level</option>
          <option value="beginner">Beginner</option>
          <option value="intermediate">Intermediate</option>
          <option value="expert">Expert</option>
        </select>
        <select
          className="filter85"
          value={rating}
          onChange={(e) => setRating(e.target.value)}
        >
          <option value="">Rating</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
        </select>
        <select
          className="filter85"
          value={monthsActive}
          onChange={(e) => setMonthsActive(e.target.value)}
        >
          <option value="">Months of Active</option>
          <option value="3">3+</option>
          <option value="6">6+</option>
          <option value="12">12+</option>
          <option value="24">24+</option>
        </select>
        <select
          className="filter85"
          value={industryExperience}
          onChange={(e) => setIndustryExperience(e.target.value)}
        >
          <option value="">Industry Experience</option>
          <option value="IT">IT</option>
          <option value="Finance">Finance</option>
          <option value="Resource">Resource</option>
          <option value="Energy">Energy</option>
          <option value="Agriculture">Agriculture</option>
        </select>
        
        <select
          className="filter85"
          value={state}
          onChange={(e) => setState(e.target.value)}
        >
          <option value="">State</option>
          <option>AndhraPradesh</option>
                  <option>ArunachalPradesh</option>
                  <option>Assam</option>
                  <option>Bihar</option>
                  <option>Chhattisgarh</option>
                  <option>Goa</option>
                  <option>Gujarat</option>
                  <option>Haryana</option>
                  <option>HimachalPradesh</option>
                  <option>Jharkhand</option>
                  <option>Karnataka</option>
                  <option>Kerala</option>
                  <option>MadhyaPradesh</option>
                  <option>Maharashtra</option>
                  <option>Manipur</option>
                  <option>Meghalaya</option>
                  <option>Mizoram</option>
                  <option>Nagaland</option>
                  <option>Odisha</option>
                  <option>Punjab</option>
                  <option>Rajasthan</option>
                  <option>Sikkim</option>
                  <option>TamilNadu</option>
                  <option>Telangana</option>
                  <option>Tripura</option>
                  <option>UttarPradesh</option>
                  <option>Uttarakhand</option>
                  <option>WestBengal</option>
        </select>
        <select
          className="filter85"
          value={city}
          onChange={(e) => setCity(e.target.value)}
        >
          <option value="">city</option>
          {state && citiesByState[state].map((cityOption) =>(
            <option key={cityOption} value={cityOption}>
                {cityOption}
            </option>
          ))}
        </select>
      </div>
      <div className="content-container85">
     
      <div className="profile-container85">
        
        
        
  {getFilteredMentors().map((mentor, index) => (

    
    
    <div className="profile85" key={index}>
    <Link to="/MentorProfile" style={{ textDecoration: 'none', color: 'white' }}>
      <img
        src={mentor.image}
        alt={`${mentor.name}'s profile`}
        className="profile-image85"
      />
      <div className="profile-details85">
        
        <h3 className="profile-name85">{mentor.name}</h3>
        <p className="profile-job-title85">Job Title: {mentor.jobTitle}</p>
        <p className="profile-industry-experience85">Industry Experience: {mentor.industryExperience}</p>
        <p className="profile-state85">State: {mentor.state}</p>
        <p className="profile-city85">City: {mentor.city}</p>
        <div className="profile-rating85">{renderStars(mentor.rating)}</div>
      </div>
      
      </Link>
      </div>
     
  ))}
</div>

       
        <div className="ads-section85">
          <h3>Sponsored Ads</h3>
          <div className="ad-box85">Google Ad 1</div>
          <div className="ad-box85">Google Ad 2</div>
        </div>
      </div>
    </div>
  );
};

export default Mentorlist;
