import React, { useState } from "react";
import "./DPIITFAQPageZZ1.css";

const FAQItemZZ1 = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`faq-item-ZZ1 ${isOpen ? "open-ZZ1" : ""}`}>
      <div className="faq-question-ZZ1" onClick={() => setIsOpen(!isOpen)}>
        <h3>{question}</h3>
        <span className="toggle-icon-ZZ1">{isOpen ? "−" : "+"}</span>
      </div>
      {isOpen && <p className="faq-answer-ZZ1">{answer}</p>}
    </div>
  );
};

const DPIITFAQPageZZ1 = () => {
  const faqs = [
    {
      question: "What is DPIIT Recognition?",
      answer:
        "DPIIT recognition is an initiative by the Department for Promotion of Industry and Internal Trade to identify and support startups. It helps startups access numerous benefits, including tax exemptions, funding opportunities, and streamlined compliance.",
    },
    {
      question: "Why is DPIIT Recognition important?",
      answer:
        "DPIIT recognition enhances your startup’s credibility and provides access to government schemes like tax exemptions, Fund of Funds, and IPR facilitation, along with easier public procurement norms.",
    },
    {
      question: "Who is eligible for DPIIT Recognition?",
      answer:
        "Startups with less than 10 years since incorporation, turnover under ₹100 crore, and focused on innovation or improvement in products or services are eligible for recognition.",
    },
    {
      question: "What are the benefits of DPIIT Recognition?",
      answer:
        "Benefits include a three-year tax holiday, fast-tracked patent and trademark processes, exemption from angel tax, and funding support under the Fund of Funds initiative.",
    },
    {
      question: "What documents are required for DPIIT Recognition?",
      answer:
        "You'll need a Certificate of Incorporation, pitch deck or brief summary of your business, proof of funding (if applicable), and details on innovation or improvement in your product or service.",
    },
    {
      question: "How to apply for DPIIT Recognition?",
      answer:
        "Register on the Startup India portal, complete the application form, and submit the required documents. Approval is granted upon meeting the eligibility criteria.",
    },
  ];

  return (
    <div className="dpiit-faq-page-ZZ1">
      {/* Header Section */}
      <header className="faq-header-ZZ1">
        <h1>FAQs on DPIIT Recognition</h1>
        <p>
          Discover all the details you need about DPIIT recognition, eligibility, benefits, and the application process.
        </p>
      </header>

      {/* FAQ Section */}
      <section className="faq-section-ZZ1">
        {faqs.map((faq, index) => (
          <FAQItemZZ1
            key={index}
            question={faq.question}
            answer={faq.answer}
          />
        ))}
      </section>

      {/* Additional Resources Section */}
      <section className="additional-resources-ZZ1">
        <h2>Additional Resources</h2>
        <ul>
          <li>
            Learn about the benefits of DPIIT recognition on the{" "}
            <a
              href="https://dpiit.gov.in/"
              target="_blank"
              rel="noopener noreferrer"
            >
              DPIIT official website
            </a>.
          </li>
        </ul>
      </section>

      {/* Footer */}
      <footer className="faq-footer-ZZ1">
        <p>
          For more queries, contact us at{" "}
          <a href="mailto:support@startupconnect.com">support@startupconnect.com</a>.
        </p>
      </footer>
    </div>
  );
};

export default DPIITFAQPageZZ1;
