import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer123">
    <div className="footer-container-desktop">
    <div className="footer-container">
      {/* Left Section */}
      <div className="footer-left">
        <div className="footer-logo">
          <img src="/images/logo.png" alt="Start-up Nexus Logo" />
        </div>
        <div className="footer-info">
          <h2>Start-up Connect</h2>
          <p>Toll Free Number: 1800 115 565</p>
          <p>Working Hrs: 10:00 am - 6:00 pm</p>
        </div>
      </div>

      {/* Middle Section */}
      <div className="footer-middle1">
        <ul>
          <li><a href="/Kairaa78">Disclaimer</a></li>
          <li><a href="/termsandcondition">Terms and Condition</a></li>
          <li><a href="/privacypolicy">Privacy Policy</a></li>
          <li><a href="/Faq12">FAQ</a></li>
          <li><a href="/contactus">Contact Us</a></li>
        </ul>
      </div>

      <div className="footer-middle2">
        <p>Quick Links</p>
        <ul>
          <li><a href="/navmenu5">Recogniton</a></li>
          <li><a href="/startup">Startup</a></li>
          <li><a href="/Investor1">Investors</a></li>
          <li><a href="/Mentorlist">Mentors</a></li>
        </ul>
      </div>

      {/* Right Section */}
      <div className="footer-right">
        <p>Last Updated:</p>
        <p>01-DEC-2024 | 05:25 PM</p>
       <div className='social-links'>
        <img src="https://i.pinimg.com/564x/46/b3/48/46b3486e747c23848bfe7fcbf0ae6dce.jpg" alt="linkedin.com" id="twitter"/>
        <img src="https://static.vecteezy.com/system/resources/thumbnails/019/874/344/small/instagram-apps-icon-free-png.png" alt="instagram.com" />
        <img src="https://cdn.pixabay.com/photo/2020/08/25/02/16/facebook-5515461_960_720.png" alt="facebook.com" />
        <img src="https://images.freeimages.com/image/large-previews/2e5/x-twitter-logo-on-grey-circle-5694255.png" alt="X" />
       </div>
      </div>
    </div>
    <hr></hr>
    <h3 className='footer-copyright'>© Startup-Connect</h3>
    </div>
    {/*Mobile View*/}
    <div className="footer-container-mobile">

      <div className="footer-lef">
        <div className="footer-log">
          <img src="/images/logo.png" alt="Start-up Nexus Logo" />
        </div>
        <div className="footer-inf">
          <h2>Start-up Connect</h2>
          <p>Toll Free Number: 1800 115 565</p>
        </div>
        <div className='social-link'>
        <img src="https://i.pinimg.com/564x/46/b3/48/46b3486e747c23848bfe7fcbf0ae6dce.jpg" alt="linkedin.com" id="twitter"/>
        <img src="https://static.vecteezy.com/system/resources/thumbnails/019/874/344/small/instagram-apps-icon-free-png.png" alt="instagram.com" />
        <img src="https://cdn.pixabay.com/photo/2020/08/25/02/16/facebook-5515461_960_720.png" alt="facebook.com" />
        <img src="https://images.freeimages.com/image/large-previews/2e5/x-twitter-logo-on-grey-circle-5694255.png" alt="X" />
       </div>
      </div>
      <div className="foot-down">
      <div className="footer-mid">
        <ul>
          <li><a href="/Kairaa78">Disclaimer</a></li>
          <li><a href="/termsandcondition">Terms and Condition</a></li>
          <li><a href="/privacypolicy">Privacy Policy</a></li>
          <li><a href="/Faq12">FAQ</a></li>
          <li><a href="/contactus">Contact Us</a></li>
        </ul>
      </div>
      <div className="footer-mid2">
        <p>Quick Links</p>
        <ul>
          <li><a href="/dpiit2">Recogniton</a></li>
          <li><a href="/startup">Startup</a></li>
          <li><a href="/Investor1">Investors</a></li>
          <li><a href="/Mentorlist">Mentors</a></li>
        </ul>
      </div>
      <div className="footer-right">
        <p>Last Updated:</p>
        <p>01-DEC-2024 | 05:25 PM</p>
      </div>
      </div>
      <hr id="mbl"></hr>
      <h3 className='copyright'>© Startup-Connect</h3>
    </div>
  </footer>
  );
};

export default Footer;
