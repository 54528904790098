import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./dashboard.css";

const Dashboard = () => {
  const [user, setUser] = useState({ name: "", email: "" });
  const [profileImage, setProfileImage] = useState(() => {
    return localStorage.getItem("profileImage") || "/images/man1.png";
  });
  const [isEditingImage, setIsEditingImage] = useState(false); // Track whether user is editing the image
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("authToken");

        const response = await fetch("https://back.startupconnectz.com/api/auth/dashboard", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) throw new Error("Failed to fetch user data");

        const data = await response.json();
        setUser(data);
      } catch (error) {
        console.error("Error fetching user data:", error);
        alert("Session expired. Please log in again.");
        navigate("/login");
      }
    };

    fetchUserData();
  }, [navigate]);

  const logout = () => {
    setProfileImage("/images/avatar.jpeg");
    alert('Logged out successfully');
    navigate('/login');
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const imageData = reader.result;
        setProfileImage(imageData); // Update the state
        localStorage.setItem("profileImage", imageData); // Store in local storage
        setIsEditingImage(false); // Stop editing after upload
      };
      reader.readAsDataURL(file);
    }
  };

  const toggleEditImage = () => {
    setIsEditingImage((prevState) => !prevState); // Toggle editing mode
  };

  return (
    <div className="dashboard-container379">
      <aside className="sidebar379">
        <h2>Dashboard</h2>
        <center>
          <img src={profileImage} alt="Profile" className="profileimg379" />
          {isEditingImage ? (
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              style={{ margin: "10px 0" }}
            />
          ) : (
            <button className="edit-logo-btn379" onClick={toggleEditImage}>
              Edit Logo
            </button>
          )}
          <p className="profile-name379">{user.name || "Loading..."}</p>
          <p className="profile-email379">{user.email || "Loading..."}</p>
        </center>
        <button className="logoutbtn379" onClick={logout}>
          Logout
        </button>
      </aside>
      <main className="main-content379">
        <header className="header">
          <h1>Welcome to Your Dashboard</h1>
          <p>Here you can manage your account and settings.</p>
        </header>
        <section className="content379">
          <div className="content-box379" onClick={() => navigate("/overview")}>
            <h2>Overview</h2>
            <p>Your recent activities and status overview.</p>
          </div>
          <div className="content-box379" onClick={() => navigate("/settings")}>
            <h2>Settings</h2>
            <p>Manage your account settings and preferences.</p>
          </div>
          <div className="content-box379" onClick={() => navigate("/profile")}>
            <h2>Profile</h2>
            <p>View and edit your personal profile information.</p>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Dashboard;
